import React, { Component } from "react"

class Redirect extends Component {
  componentDidMount() {
    window.location.replace("https://convaise.com")
  }

  render() {
    return <div />
  }
}

export default Redirect
